module.exports = {
  lang: "English",
  Home: "Home",
  Index: "Index",
  Rank: "Rank",
  Slot: "Slot",
  SubmitProject: "Submit Project",
  All: "All",
  Login: "Login",
  Logout: "Logout",
  Details: "Details",
  NoInformation: "No Information",
  ViewAll: "View All",
  Project: "Project",
  DevelopmentActivity: "Development <br> Activity",
  ActivityCurve: "Activity <br> Curve",
  Token: "Token",
  PriceChange: "Price/24h <br> Change",
  Last7Days: "Last 7 Days",
  UnderlyingTokens:"Underlying Tokens",
  QuantityPerSet:"Quantity per Set",
  TokenPrice:"Token Price",
  CurrentPriceAllocation:"Current Price <br> Allocation",
  PercentChange:"Percent Change",
  TotalPricePerSet: "Total Price <br> per Set",
  About: "About",
  IndexAboutInfo: "The KusamaProject Index is a capitalization-weighted index that tracks the performance of decentralized financial assets across the market.",
  Back: "Back",
  Voters: "Voters",
  event: "event",
  VisitWebsite: "Visit Website",
  Share: "Share",
  ParentChain: "ParentChain",
  SubProjects: "SubProjects",
  SubscribeToUpdates: "Subscribe to Updates",
  YourEmail: "Your email",
  Subscribe: "Subscribe",
  SubmitThisProject: "Submit an update on this project",
  projectAboutInfo:"All info in this site is purely educational and should only be used to inform your own research. We're not offering investment advice, endorsement of any project or approach, or promise of any outcome.",
  D:"1D",
  W:"1W",
  M:"1M",
  Y:"1Y",
  Search:"Search",
  NoResultsFound: "No results were found for w%",
  NoResultsInfo: "Suggest: <br>Please make sure all words are spelled correctly. <br> Please try a different keyword. <br> Please try a broader keyword.",
  NoMore: "No More",
  Title:"Title",
  ProjectTitle: "Project title",
  Category:"Category",
  SelectCategory: "Select Category",
  ShortIntroduction:"Short Introduction",
  ShortIntroductionLimit: "Short Introduction (Limit 256 Characters)",
  DetailDescription: "Detail Description",
  Website: "Website",
  Email: "Email",
  PLOTokenAllocationPlan:"Official Announcement Link of PLO Token Allocation Plan",
  Submit: "Submit",
  EnterTitle:"Please fill in the title",
  EnterCategory:"Please select a category",
  EnterShortIntroduction:"Please fill in the short introduction",
  EnterIntroductionLimited:"Introduction is limited to 256 characters",
  SubmitSuccess:"Submit Success",
  ERROR:"ERROR",
  noMathExtension: "Please connect Math Wallet first",
  noAddress: "Please switch to Polkadot wallet operation",
  SelectAccount: "Select Account",
  noAccount: "No account",
  LogInMathWallet: "Log In with MathWallet Extension",
  LogInPolkadotJS: "Log In with PolkadotJS Extension",
  SelectLoginAccount: "Please select a wallet to login",
  CopySuccess: "Successfully copied",
  CopyFail: "Copy failed",
  GetMore: "Get More",
  Fold: "Fold",
  LatestProjects: "Latest Projects",
  AuctionCrowdloan: "Auction & Crowdloan",
  Join: "Join",
  LikeFailed: "Operation Failed",
};
